import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Customer from '../../interfaces/Customer';

import Moment from 'moment';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function CustomersForm() {

  const history = useHistory();

  const { store } = useAuth();

  const { id } = useParams();

  const initialValues: Customer = {
    id: "",
    name: "",
    surname: "",
    birthday: "",
    customer_document: "",
    email: "",
    entry_date: "",
    phone_number: "",
    points_balance: 0,
    cashback_vip: false
  };

  const [customer, setCustomer] = useState<Customer>(initialValues);

  useEffect(() => {
    if (!!id) {
      api.get(`customers/${id}`).then(response => {
        const data = response.data;
        if(data){
          data.birthday = formatDateForDisplay(data.birthday);// Formata a data para exibição
        }
        setCustomer(data);
      });
    }
  }, [id]);

  const formatDateForDisplay = (value: string): string => {
    if (!value) return "";

    // Converte para Moment e formata para DD/MM/YYYY
    return Moment.utc(value).format("DD/MM/YYYY");
  };

  const formatDateForBackend = (value: string): string => {
    if (!value) return "";

    // Converte de DD/MM/YYYY para YYYY-MM-DD
    const [day, month, year] = value.split("/");
    return `${year}-${month}-${day}`;
  };


  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    // Verifica se o campo é "birthday" para formatar corretamente
    if (name === "birthday") {
      // Permite apenas números e barras durante a digitação
      const onlyNumbers = value.replace(/\D/g, "");

      // Aplica a máscara conforme o tamanho do valor
      let formattedValue = onlyNumbers;
      if (onlyNumbers.length > 2) formattedValue = `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2)}`;
      if (onlyNumbers.length > 4) formattedValue = `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2, 4)}/${onlyNumbers.slice(4)}`;

      setCustomer({ ...customer, [name]: formattedValue });
      return;
    }

    setCustomer({ ...customer, [name]: value });
  }


  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setCustomer({...customer, [name]: checked});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
      async (event: FormEvent) => {
        try {
          event.preventDefault();

          const data = {
            ...customer,
            birthday: formatDateForBackend(customer.birthday), // Converte a data para o formato do backend
          };

          if (!!customer.id) {
            await api.put(`customers/${customer.id}`, data);
            alert("Cliente atualizado!");
          } else {
            await api.post("customers", data);
            alert("Cliente incluído!");
          }

          history.push("/customers");
        } catch (err) {
          if (!!customer.id) {
            alert("Erro ao atualizar o cliente!");
          } else {
            alert("Erro ao cadastrar o cliente!");
          }
        }
      },
      [history, customer]
  );


  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Cliente"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="CPF/CNPJ">
              <Form.Input
                name="customer_document"
                value={customer.customer_document || ""}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={customer.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Sobrenome">
              <Form.Input
                name="surname"
                value={customer.surname || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="E-Mail">
              <Form.Input
                name="email"
                value={customer.email}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Telefone">
              <Form.Input
                name="phone_number"
                value={customer.phone_number}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Data de Aniversário">
              <Form.Input
                name="birthday"
                value={customer.birthday}
                onChange={handleChange}
                readOnly={!!id}
                maxLength={10}
              />
            </Form.Group>
            <Form.Group label="Saldo">
              <Form.Input
                name="points_balance"
                value={customer.points_balance}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
            { store.program_type === 2 &&
              <Form.Group label="VIP">
                <Form.Switch
                  name="cashback_vip"
                  checked={!!customer.cashback_vip ? true : false}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            }
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default CustomersForm;