const csvHeaderFileRansomed = [
    { label: "Nome Completo", key: "name" },
    { label: "E-mail", key: "email" },
    { label: "Documento", key: "customer_document" },
    { label: "Valor", key: "value" },
    { label: "Data de Aniversário", key: "birthday" },
    { label: "Número de Telefone", key: "phone_number"},
    { label: "Estornado", key: "reversed"},
    { label: "Data do resgate", key:"date_time"}
];

const csvHeaderFileAvailable = [
  { label: "Nome Completo", key: "name" },
  { label: "Documento", key: "customer_document" },
  { label: "E-mail", key: "email" },
  { label: "Número de Telefone", key: "phone_number" },
  { label: "Data de Aniversário", key: "birthday" },
  { label: "Saldo de Cashback Disponível", key: "points_balance" },
  { label: "Saldo de Cashback a Expirar", key: "value"},
  { label: "Fato Gerador", key: "transaction_type"},
  { label: "Data de Expiração", key:"expiration_date"},
  { label: "Data da Compra", key:"created_at"},
];
  
const monthsInPortuguese = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

export {csvHeaderFileAvailable,csvHeaderFileRansomed, monthsInPortuguese}

export default interface Customer {
  name: string;
  email: string;
  customer_document: string;
  birthday: string;
  phone_number: string
}

export default interface Recovery {
  customer: Customer;
  birthday: string;
  value: number;
  reversed: boolean;
  date_time: string; 
  surname: string;
}